import { observable, computed, makeObservable } from 'mobx'
import FactoryProvider from 'providers/factoryProvider'
import BaseModel from '../baseModel'
import FactoryModel from '../factoryModel'

class DictModel extends BaseModel {
  @observable dict = {}
  @observable isLoading = false

  constructor() {
    super()
    makeObservable(this)
  }

  getDict = async () => {
    try {
      this.applyData({ isLoading: true })
      const dict = await FactoryProvider.DictProvider.getDict()
      this.applyData({ dict })
    } finally {
      this.applyData({ isLoading: false })
    }
  }

  @computed get opfShorts() {
    return this.createRelationMap(this.opfShortFormRelations)
  }
  @computed get okvBusinessTypeRelations() {
    return this.createRelationMap(this.opfShortFormRelations)
  }

  createRelationMap(relations) {
    const arr = relations.map(({ value, text }) => [value, text])

    return Object.fromEntries(arr)
  }

  get opfShortFormRelations() {
    return this.dict.opf_short_form_of_ownership_relations
  }
}

export { DictModel }
