import _ from 'lodash'
import { observable, action, computed, makeObservable } from 'mobx'
import {
  validRequired, validEmail, parseDate, CyrillicNameWithSpace, CyrillicName,
  getDateByAge, moment, dateLessThan, dateGreaterThan, safeObject,
  lessThanCurrentDate, phoneNumberNormalizer, notifyError, isSafe, scrollTo
} from 'uikit'
import deepMerge from 'deepmerge'
import { validationAddress } from 'utils/validators/address'
import { validInn } from 'utils/validators/inn'
import { validateSnils } from 'utils/validators/snils'
import { validAutoNumber } from 'utils/validators/autoNumber'
import {
  FAMILY_STATUS_MARRIED,
  FAMILY_STATUS_CIVIL_MARRIAGE,
  EMPLOYMENT_STATUSES_WITHOUT_WORK
} from 'utils/dict'
import FactoryProvider from 'providers/factoryProvider'
import { scanMap } from 'providers/helpers/poscans'
import { prepareErrorMessage } from 'utils/error'
import { CUSTOM_STEP } from 'providers/helpers/questionary'
import BaseModel from '../../baseModel'
import { DictModel } from '../../dictModel'
import FactoryModel from '../../factoryModel'

const PHOTO_FIELDS = [
  'isWithoutPhoto'
]

const CONTACTS_FIELDS = [
  'email'
]

const EDUCATION_FIELDS = [
  'education'
]

const FAMILY_FIELDS = [
  'familyType',
  'familyChildren',
  'familySpouseLastName',
  'familySpouseFirstName',
  'familySpousePatronymic',
  'familySpouseFullName'
]

const EMPLOYMENT_FIELDS = [
  'employmentType',
  'landlinePhone',
  'employmentDate',
  'workPhone',
  'activityType',
  'employmentPosition',
  'ownershipType',
  'organization',
  'organizationAddress',
  'organizationInn',
  'bussinessType'
]

const INCOMING_FIELDS = [
  'incoming'
]

const CONTACT_PERSON_FIELDS = [
  'contactFirstName',
  'contactPatronymic',
  'contactStatus',
  'contactPhone'
]

const REALTY_FIELDS = [
  'realty'
]

const AUTO_FIELDS = [
  'auto'
]

const DOCUMENTS_FIELDS = [
  'documents'
]

const KEYWORD_FIELDS = [
  'keyword'
]

const OPTIONS = [
  'isRealty',
  'isAuto',
  'isDocs'
]

const fields = [
  ...PHOTO_FIELDS,
  ...CONTACTS_FIELDS,
  ...EDUCATION_FIELDS,
  ...FAMILY_FIELDS,
  ...EMPLOYMENT_FIELDS,
  ...INCOMING_FIELDS,
  ...CONTACT_PERSON_FIELDS,
  ...REALTY_FIELDS,
  ...AUTO_FIELDS,
  ...DOCUMENTS_FIELDS,
  ...KEYWORD_FIELDS
]

class OtherModel extends BaseModel {
  constructor() {
    super(fields)
    this.applyOnChangeHook(fields, this.handleChangeField)
    makeObservable(this)
  }

  PHOTO_BLOCK = 'otherData:photo'
  CONTACTS_BLOCK = 'otherData:contacts'
  EDUCATION_BLOCK = 'otherData:education'
  FAMILY_BLOCK = 'otherData:family'
  EMPLOYMENT_BLOCK = 'otherData:employment'
  INCOMES_BLOCK = 'otherData:incomes'
  CONTACT_PERSON_BLOCK = 'otherData:contactPerson'
  REALTY_BLOCK = 'otherData:realty'
  AUTO_BLOCK = 'otherData:auto'
  DOCUMENTS_BLOCK = 'otherData:documents'
  KEYWORD_BLOCK = 'otherData:keyword'

  ctx = null

  @observable isInit = false

  @observable isWithoutPhoto = false
  @observable isProcessAddPhoto = false

  @observable email = ''
  @observable isChangeContacts = false

  @observable education = null
  @observable isChangeEducation = false

  @observable familyType = null
  @observable familyChildren = 0
  @observable familySpouseLastName = ''
  @observable familySpouseFirstName = ''
  @observable familySpousePatronymic = ''
  @observable familySpouseFullName = ''
  @observable isChangeFamily = false

  @observable employmentType = null
  @observable landlinePhone = ''
  @observable employmentDate = null
  @observable workPhone = ''
  @observable activityType = null
  @observable employmentPosition = ''
  @observable ownershipType = null
  @observable organization = ''
  @observable organizationAddress = null
  @observable organizationInn = ''
  @observable bussinessType = null
  @observable isChangeEmployment = false

  @observable incoming = []
  @observable isChangeIncoming = false

  @observable contactFirstName = ''
  @observable contactPatronymic = ''
  @observable contactStatus = null
  @observable contactPhone = ''
  @observable isChangeContactPerson = false

  @observable realty = []
  @observable isRealty = false
  @observable isChangeRealty = false

  @observable auto = []
  @observable isAuto = false
  @observable isChangeAuto = false

  @observable documents = []
  @observable isChangeDocuments = false
  @observable isDocs = false

  @observable keyword = ''
  @observable isChangeKeyword = false

  @observable photoUrl = ''
  @observable photoBanks = []
  @observable photoScanId = null
  @observable isLoadingPhoto = false
  @observable isProcessSaveCamera = false
  @observable isProcessPhoto = false

  @observable isForceValidate = false
  @observable isLoading = false
  @observable isProcessSave = false

  clear = action(() => {
    this.isWithoutPhoto = false
    this.isProcessAddPhoto = false

    this.email = ''
    this.isChangeContacts = false

    this.education = null
    this.isChangeEducation = false

    this.familyType = null
    this.familyChildren = 0
    this.familySpouseLastName = ''
    this.familySpouseFirstName = ''
    this.familySpousePatronymic = ''
    this.familySpouseFullName = ''
    this.isChangeFamily = false

    this.employmentType = null
    this.landlinePhone = ''
    this.employmentDate = null
    this.workPhone = ''
    this.activityType = null
    this.employmentPosition = ''
    this.organization = null
    this.organizationAddress = null
    this.organizationInn = ''
    this.ownershipType = null
    this.bussinessType = null
    this.isChangeEmployment = false

    this.incoming = []
    this.isChangeIncoming = false

    this.contactFirstName = ''
    this.contactPatronymic = ''
    this.contactStatus = null
    this.contactPhone = ''
    this.isChangeContactPerson = false

    this.realty = []
    this.isRealty = false
    this.isChangeRealty = false

    this.auto = []
    this.isAuto = false
    this.isChangeAuto = false

    this.documents = []
    this.isChangeDocuments = false
    this.isDocs = false

    this.keyword = ''
    this.isChangeKeyword = false

    this.clearPhotoPackage()
    this.photoUrl = ''
    this.photoBanks = []
    this.photoScanId = null
    this.isLoadingPhoto = false
    this.isProcessSaveCamera = false
    this.isProcessPhoto = false

    this.isForceValidate = false
    this.isLoading = false
    this.isProcessSave = false
  })

  clearPhotoPackage = () => {
    if (this.ctx.CommonModel.packageId) FactoryProvider.PoscansWsProvider.close(this.ctx.CommonModel.packageId)
  }

  @computed get isValidPhoto() {
    if (this.isWithoutPhoto) return true
    return !(!this.isWithoutPhoto && !this.photoUrl)
  }

  @computed get validEmail() {
    if (validRequired(this.email)) return validRequired(this.email)
    if (validEmail(this.email)) return validEmail(this.email)
    return null
  }

  @computed get validEducation() {
    if (validRequired(this.education)) return validRequired(this.education)
    return null
  }

  @computed get validFamilyType() {
    if (validRequired(this.familyType)) return validRequired(this.familyType)
    return null
  }

  @computed get hasSpouse() {
    return this.familyType === FAMILY_STATUS_MARRIED || this.familyType === FAMILY_STATUS_CIVIL_MARRIAGE
  }

  @computed get validFamilyChildren() {
    if (validRequired(this.familyChildren)) return validRequired(this.familyChildren)

    return null
  }

  @computed get validFamilySpouseFio() {
    if (!this.hasSpouse) return null

    if (validRequired(this.familySpouseLastName) || validRequired(this.familySpouseFirstName)) return 'Фамилия обязательна'
    if (!CyrillicNameWithSpace.test(this.familySpouseLastName)) return 'Допустимы только кириллические символы'
    if (!CyrillicNameWithSpace.test(this.familySpouseFirstName)) return 'Допустимы только кириллические символы'
    if (this.familySpousePatronymic !== '' && !CyrillicNameWithSpace.test(this.familySpousePatronymic)) return 'Допустимы только кириллические символы'

    return null
  }

  @computed get validEmploymentType() {
    if (validRequired(this.employmentType)) return validRequired(this.employmentType)
    return null
  }

  @computed get hasEmployment() {
    return !validRequired(this.employmentType) && _.findIndex(EMPLOYMENT_STATUSES_WITHOUT_WORK, e => e === this.employmentType) === -1
  }

  @computed get validEmploymentDate() {
    if (!this.hasEmployment) return null

    if (validRequired(this.employmentDate)) return validRequired(this.employmentDate)
    if (lessThanCurrentDate(this.employmentDate)) return lessThanCurrentDate(this.employmentDate)

    const birthDate = this.ctx.MainModel.birthDate
    if (birthDate && !parseDate(this.employmentDate).isSameOrAfter(parseDate(birthDate))) {
      return 'Не может быть раньше даты рождения'
    }

    return null
  }

  @computed get validLandlinePhone() {
    const phone = this.ctx.MainModel.phone
    if (this.hasEmployment) return null
    if (this.landlinePhone === '' && !this.hasEmployment) return 'Обязательное поле'
    if (`${this.landlinePhone}`.length !== 10) return 'Неверный формат'
    if (this.landlinePhone === phone) return `Совпадает с +7 ${phoneNumberNormalizer(phone)}`
    return null
  }

  @computed get validWorkPhone() {
    if (!this.hasEmployment) return null

    const phone = this.ctx.MainModel.phone
    if (validRequired(this.workPhone)) return validRequired(this.workPhone)
    if (this.workPhone === phone) return `Совпадает с +7 ${phoneNumberNormalizer(phone)}`
    if (this.workPhone.length !== 10) return 'Неверный формат'
    return null
  }

  @computed get validActivityType() {
    if (!this.hasEmployment) return null

    if (validRequired(this.activityType)) return validRequired(this.activityType)
    return null
  }

  @computed get validEmploymentPosition() {
    if (!this.hasEmployment) return null

    if (validRequired(this.employmentPosition)) return validRequired(this.employmentPosition)
    return null
  }

  @computed get validOrganization() {
    if (!this.hasEmployment) return null
    if (validRequired(this.organization)) return validRequired(this.organization)
    if (this.organization.length < 2) return 'Длина меньше 2 символов'
    if (this.organization.length > 100) return `Наименование организации содержит ${this.organization.length} символов. Сократите до 100 символов.`
    return null
  }

  @computed get validOrganizationAddress() {
    if (!this.hasEmployment) return null

    if (validRequired(this.organizationAddress)) return validRequired(this.organizationAddress)
    if (validationAddress(this.organizationAddress, true).validation) return 'Некорректный адрес, воспользуйтесь ручным вводом'
    return null
  }

  @computed get validOrganizationInn() {
    if (!this.hasEmployment) return null
    if (this.organizationInn === '') return null
    if (validInn(this.organizationInn, false)) return validInn(this.organizationInn, false)
    return null
  }

  @computed get validOwnershipType() {
    if (!this.hasEmployment) return null

    if (validRequired(this.ownershipType)) return validRequired(this.ownershipType)
    return null
  }

  @computed get validBussinessType() {
    if (!this.hasEmployment) return null

    if (validRequired(this.bussinessType)) return validRequired(this.bussinessType)
    return null
  }

  @computed get validIncoming() {
    return _.map(this.incoming, income => ({
      type: this.validIncomingType(income.type),
      price: this.validIncomingPrice(income.price)
    }))
  }

  validIncomingType = type => {
    if (validRequired(type)) return validRequired(type)
    return null
  }

  validIncomingPrice = price => {
    if (validRequired(price)) return validRequired(price)
    if (price <= 0) return 'Меньше 0'
    return null
  }

  @computed get isValidAllIncoming() {
    return _.isEmpty(_.filter(this.incoming, income => this.validIncomingType(income.type) || this.validIncomingPrice(income.price))) &&
      !_.isEmpty(this.incoming)
  }

  @computed get validContactFirstName() {
    if (validRequired(this.contactFirstName)) return validRequired(this.contactFirstName)
    if (!CyrillicNameWithSpace.test(this.contactFirstName)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validContactPatronymic() {
    if (this.contactPatronymic === '') return null

    if (!CyrillicNameWithSpace.test(this.contactPatronymic)) return 'Допустимы только кириллические символы'
    return null
  }

  @computed get validContactStatus() {
    if (validRequired(this.contactStatus)) return validRequired(this.contactStatus)
    return null
  }

  @computed get validContactPhone() {
    const phone = this.ctx.MainModel.phone

    if (validRequired(this.contactPhone)) return validRequired(this.contactPhone)
    if (this.contactPhone.length !== 10) return 'Неверный формат'
    if (_.get(this.contactPhone.replace(/[\s()-]*/gi, ''), '0') !== '9') return 'Начинается не с 9'
    if (this.contactPhone === phone) return `Не должен совпадать с +7 ${phoneNumberNormalizer(phone)}`
    if (this.hasEmployment && this.contactPhone === this.workPhone) return `Совпадает с +7 ${phoneNumberNormalizer(this.workPhone)}`
    if (!this.hasEmployment && this.contactPhone === this.landlinePhone) return `Совпадает с +7 ${phoneNumberNormalizer(this.landlinePhone)}`

    return null
  }

  validRealtyType = type => {
    if (validRequired(type)) return validRequired(type)
    return null
  }

  @computed get isValidAllRealty() {
    if (!this.isRealty) return true
    return _.isEmpty(_.filter(this.realty, r => this.validRealtyType(r.type)))
  }

  validAutoProducer = producer => {
    if (validRequired(producer)) return 'Не указан'
    return null
  }

  validAutoModel = model => {
    if (validRequired(model)) return 'Не указан'
    return null
  }

  validAutoYear = year => {
    if (validRequired(year)) return 'Не указан'
    return null
  }

  validAutoNumber = number => {
    if (validRequired(number)) return 'Не указан'
    return validAutoNumber(number)
  }

  @computed get isValidAllAuto() {
    if (!this.isAuto) return true
    return _.isEmpty(_.filter(this.auto, a => this.validAutoProducer(a.producer) || this.validAutoModel(a.model) ||
      this.validAutoYear(a.year) || this.validAutoNumber(a.number)))
  }

  @computed get validDocument() {
    const documentTin = _.find(this.documents, d => d.type === 'tin')
    const tin = documentTin ? {
      series: validRequired(documentTin.series) ? 'Неверная' :
        documentTin.series.length < 2 ? 'Неверная' : null,
      number: validRequired(documentTin.number) ? 'Не указан' :
        documentTin.number.length < 9 ? 'Неверный' : null,
      inn: validRequired(documentTin.inn) ? 'Не указан' :
        _.isNaN(Number(documentTin.inn)) || !_.isInteger(Number(documentTin.inn)) ? 'Не число' : validInn(documentTin.inn),
      date: validRequired(documentTin.date) ? 'Не указан' :
        dateLessThan(documentTin.date, moment(), 'Некорректная')
    } : null

    const documentDriver = _.find(this.documents, d => d.type === 'driver')
    const driver = documentDriver ? {
      series: validRequired(documentDriver.series) ? 'Неверная' :
        documentDriver.series.length < 4 ? 'Неверная' : null,
      number: validRequired(documentDriver.number) ? 'Не указан' :
        _.isNaN(Number(documentDriver.number)) || !_.isInteger(Number(documentDriver.number)) ? 'Не число' :
          documentDriver.number.length !== 6 ? 'Некорректный номер' :
            null,
      by: validRequired(documentDriver.by) ? 'Не указан' : null,
      date: validRequired(documentDriver.date) ? 'Не указана' :
        dateLessThan(documentDriver.date, moment(), 'Некорректная'),
      dateBefore: validRequired(documentDriver.dateBefore) ? 'Не указан' :
        dateGreaterThan(documentDriver.dateBefore, moment(), 'Некорректная')
    } : null

    const documentSnils = _.find(this.documents, d => d.type === 'snils')
    const snils = documentSnils ? {
      number: validRequired(documentSnils.number) ? 'Не указан' :
        _.isNaN(Number(documentSnils.number)) || !_.isInteger(Number(documentSnils.number)) ? 'Не число' : validateSnils(documentSnils.number)
    } : null

    return {
      tin,
      driver,
      snils
    }
  }

  @computed get isValidAllDocuments() {
    if (!this.isDocs) return true
    return _.isEmpty(_.omitBy(this.validDocument.tin, _.isNull)) &&
      _.isEmpty(_.omitBy(this.validDocument.driver, _.isNull)) &&
    _.isEmpty(_.omitBy(this.validDocument.snils, _.isNull))
  }

  @computed get validConfirmPhoneInput() {
    if (validRequired(this.confirmPhone)) return validRequired(this.confirmPhone)
    if (this.confirmPhone.length !== 10) return 'Неверный формат'
    if (_.get(this.confirmPhone.replace(/[\s()-]*/gi, ''), '0') !== '9') return 'Начинается не с 9'
    return null
  }

  @computed get validConfirmSmsCode() {
    if (validRequired(this.confirmSmsCode)) return 'Не указан'
    return null
  }

  @computed get isValidContacts() {
    return !this.validEmail
  }

  @computed get isValidEducation() {
    return !this.validEducation
  }

  @computed get isValidFamily() {
    return !this.validFamilyType && !this.validFamilyChildren && !this.validFamilySpouseFio
  }

  @computed get isValidEmployment() {
    return !this.validEmploymentType && !this.validOrganization && !this.validOrganizationAddress &&
      !this.validOrganizationInn && !this.validOwnershipType && !this.validBussinessType && !this.validActivityType &&
      !this.validEmploymentPosition && !this.validWorkPhone && !this.validEmploymentDate && !this.validLandlinePhone
  }

  @computed get isValidContactPerson() {
    return !this.validContactFirstName && !this.validContactPatronymic &&
      !this.validContactStatus && !this.validContactPhone
  }

  @computed get validKeyword() {
    if (validRequired(this.keyword)) return validRequired(this.keyword)
    if (!CyrillicName.test(this.keyword)) return 'Только одно слово на русском'
    if (this.keyword.length < 5) return 'Меньше 5 символов'
    return null
  }

  @computed get isNext() {
    return this.isValidPhoto &&
      this.isValidContacts && this.isValidEducation && !this.validLandlinePhone &&
      this.isValidFamily && this.isValidEmployment &&
      this.isValidAllIncoming && this.isValidContactPerson &&
      this.isValidAllRealty && this.isValidAllAuto && this.isValidAllDocuments
  }

  setSpouseFullName = action((fullName = '') => {
    this.applyData({
      familySpouseFullName: fullName,
      familySpouseLastName: '',
      familySpouseFirstName: '',
      familySpousePatronymic: ''
    })
  })

  setFioSpouse = action(item => {
    const { name, surname, patronymic, gender } = item
    let fullName = ''
    if (surname) fullName += `${surname} `
    if (name) fullName += `${name} `
    if (patronymic) fullName += `${patronymic}`
    this.applyData(safeObject({
      familySpouseFirstName: name,
      familySpouseLastName: surname,
      familySpousePatronymic: patronymic,
      familySpouseFullName: fullName
    }))
  })

  transformCount = action((field, type) => {
    if (validRequired(this[field])) {
      this[field] = 0
      return
    }
    switch (type) {
      case 'subtraction':
        if (Number(this[field]) <= 0) break
        this[field] = Number(this[field]) - 1
        break
      case 'add':
        this[field] = Number(this[field]) + 1
        break
    }
  })

  setEmploymentType = type => {
    this.applyData({ employmentType: type })
  }

  setOrganization = action(organization => {
    this.organization = organization.name
    this.organizationAddress = organization.address
    this.organizationInn = organization.inn

    if (organization.opfShortName) {
      this.ownershipType = FactoryModel.DictModel.opfShorts[organization.opfShortName]
    }

    if (organization.okved) {
      this.bussinessType = FactoryModel.DictModel.okvBusinessTypeRelations[organization.okved.split('.')[0]]
    }
  })

  addIncoming = action(() => {
    const incoming = deepMerge([], this.incoming)
    incoming.push({ type: null, price: '' })
    this.incoming = incoming
  })

  setIncoming = (index, field) => action(value => {
    this.incoming[index][field] = value
  })

  removeIncoming = action(index => {
    const incoming = deepMerge([], this.incoming)
    incoming.splice(index, 1)
    this.incoming = incoming
  })

  addRealty = action(() => {
    const realty = deepMerge([], this.realty)
    realty.push({ type: null })
    this.realty = realty
  })

  setRealty = index => action(type => {
    this.realty[index].type = type
  })

  removeRealty = action(index => {
    const realty = deepMerge([], this.realty)
    realty.splice(index, 1)
    this.realty = realty
  })

  addAuto = action(() => {
    const auto = deepMerge([], this.auto)
    auto.push({ producer: '', model: '', year: '', number: '' })
    this.auto = auto
  })

  setAuto = (index, field) => action(value => {
    this.auto[index][field] = value
  })

  removeAuto = action(index => {
    const auto = deepMerge([], this.auto)
    auto.splice(index, 1)
    this.auto = auto
  })

  selectDocument = action(type => {
    const documents = deepMerge([], this.documents)
    switch (type) {
      case 'tin':
        documents.push({
          type: 'tin',
          series: '',
          number: '',
          inn: '',
          date: null
        })
        break
      case 'driver':
        documents.push({
          type: 'driver',
          series: '',
          number: '',
          by: '',
          date: null,
          dateBefore: null
        })
        break
      case 'snils':
        documents.push({
          type: 'snils',
          number: ''
        })
        break
    }
    this.applyData({
      documents
    })
  })

  setDocumentField = (type, field) => action(value => {
    const index = _.findIndex(this.documents, d => d.type === type)
    if (index === -1) return
    this.documents[index][field] = value
  })

  removeDocument = action(type => {
    const index = _.findIndex(this.documents, d => d.type === type)
    if (index === -1) return
    this.documents.splice(index, 1)
  })

  handleChangeField = ({ field }) => {
    if (!this.isInit) return

    if (_.findIndex(CONTACTS_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeContacts: true })
    }
    if (_.findIndex(EDUCATION_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeEducation: true })
    }
    if (_.findIndex(FAMILY_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeFamily: true })
    }
    if (_.findIndex(EMPLOYMENT_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeEmployment: true })
    }
    if (_.findIndex(INCOMING_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeIncoming: true })
    }
    if (_.findIndex(CONTACT_PERSON_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeContactPerson: true })
    }
    if (_.findIndex(REALTY_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeRealty: true })
    }
    if (_.findIndex(AUTO_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeAuto: true })
    }
    if (_.findIndex(DOCUMENTS_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeDocuments: true })
    }
    if (_.findIndex(KEYWORD_FIELDS, c => c === field) !== -1) {
      this.applyData({ isChangeKeyword: true })
    }
  }

  @computed get photoFieldStatus() {
    if (!this.isWithoutPhoto && !this.ctx.CommonModel.packageId) return 'error'
    if (!this.isWithoutPhoto && !this.photoUrl) return 'error'
    return 'success'
  }

  @computed get photoFieldStatusText() {
    if (!this.isWithoutPhoto && !this.photoUrl) return 'Загрузите фото'
    return ''
  }

  @computed get contactsFieldsStatus() {
    if (!this.isChangeContacts && !this.isForceValidate) return 'wait'
    return this.isValidContacts ? 'success' : 'error'
  }

  @computed get educationFieldsStatus() {
    if (!this.isChangeEducation && !this.isForceValidate) return 'wait'
    return this.isValidEducation ? 'success' : 'error'
  }

  @computed get familyFieldsStatus() {
    if (!this.isChangeFamily && !this.isForceValidate) return 'wait'
    return this.isValidFamily ? 'success' : 'error'
  }

  @computed get employmentFieldsStatus() {
    if (!this.isChangeEmployment && !this.isForceValidate) return 'wait'
    return this.isValidEmployment ? 'success' : 'error'
  }

  @computed get incomingFieldsStatus() {
    if (!this.isChangeIncoming && !this.isForceValidate) return 'wait'
    return this.isValidAllIncoming ? 'success' : 'error'
  }

  @computed get contactPersonFieldsStatus() {
    if (!this.isChangeContactPerson && !this.isForceValidate) return 'wait'
    return this.isValidContactPerson ? 'success' : 'error'
  }

  @computed get realtyFieldsStatus() {
    if (!this.isChangeRealty || _.isEmpty(this.realty)) return 'wait'
    return this.isValidAllRealty ? 'success' : 'error'
  }

  @computed get autoFieldsStatus() {
    if (!this.isChangeAuto || _.isEmpty(this.auto)) return 'wait'
    return this.isValidAllAuto ? 'success' : 'error'
  }

  @computed get documentsFieldsStatus() {
    if (!this.isChangeDocuments || _.isEmpty(this.documents)) return 'wait'
    return this.isValidAllDocuments ? 'success' : 'error'
  }

  @computed get keywordFieldsStatus() {
    if (!this.isChangeKeyword && !this.isForceValidate) return 'wait'
    return !this.validKeyword ? 'success' : 'error'
  }

  @computed get isWithPhoto() {
    return !this.isWithoutPhoto &&
      !!this.ctx.CommonModel.packageId
  }

  get = (questionary = null) => {
    const resolve = {}
    _.each([...fields, ...OPTIONS], field => {
      const value = questionary ? questionary[field] : this[field]
      if (value !== undefined) {
        resolve[field] = value
      }
    })
    return resolve
  }

  applyOtherData = (data, isInit = true) => {
    this.applyData(this.get(data))
    if (data?.photo) this.applyPhoto(data?.photo)
    this.applyData({
      isInit,
      isForceValidate: this.isNext
    })

    this.action(() => {
      if (this.isValidContacts) this.isChangeContacts = true
      if (this.isValidEducation) this.isChangeEducation = true
      if (this.isValidFamily) this.isChangeFamily = true
      if (this.isValidEmployment) this.isChangeEmployment = true
      if (this.isValidAllIncoming) this.isChangeIncoming = true
      if (this.isValidAllRealty) this.isChangeRealty = true
      if (this.isValidAllAuto) this.isChangeAuto = true
      if (this.isValidAllDocuments) this.isChangeDocuments = true
    })
  }

  applyPhoto = action(photo => {
    this.photoUrl = photo?.url
    this.photoScanId = photo?.id
  })

  initChange = () => {
    this.applyData({
      isInit: true
    })
  }

  applyOptions({ photo: { photoBanks }, isRealty, isAuto, isDocs }) {
    this.action(() => {
      this.photoBanks = photoBanks
      this.isRealty = isRealty
      this.isDocs = isDocs
      this.isAuto = isAuto
    })
  }

  addPhoto = async () => {
    if (this.ctx.CommonModel.packageId) return
    try {
      this.applyData({ isProcessAddPhoto: true })
      const { photoPackageId, photoQrCode, photoScanId } = await FactoryProvider.QuestionaryProvider.addPhoto(this.ctx.CommonModel.id)
      this.applyData({
        photoScanId,
        isWithoutPhoto: false
      })
      this.ctx.CommonModel.setField('packageId')(photoPackageId)
      this.ctx.CommonModel.setField('qrCode')(photoQrCode)
      this.initPhoto(true)
    } catch (e) {
      notifyError('Ошибка добавления фото', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ isProcessAddPhoto: false })
    }
  }

  initPhoto = async (isForce = false) => {
    if (!this.ctx.CommonModel.packageId && !isForce) return

    FactoryProvider.PoscansWsProvider.subscribe({
      packageID: this.ctx.CommonModel.packageId,
      onReceived: this.handleReceivePhoto
    })
  }

  handleReceivePhoto = source => {
    switch (source?.Action) {
      case 'Upload': {
        if (!source?.Payload) break

        const scan = scanMap({ scan: source?.Payload })
        this.applyData({
          photoUrl: scan.url,
          photoScanId: scan.id
        })
      }
    }
  }

  uploadPhoto = async ({ file, metadata = null, isCamera = false }) => {
    if (!isSafe(this.ctx.CommonModel.packageId) || !isSafe(this.photoScanId)) return

    try {
      this.applyData({ [!isCamera ? 'isProcessPhoto' : 'isProcessSaveCamera']: true })
      const { url } = await FactoryProvider.PoscansProvider.putScan({
        packageID: this.ctx.CommonModel.packageId,
        scanID: this.photoScanId,
        file,
        metadata
      })
      this.applyData({ photoUrl: url })
    } catch (e) {
      notifyError('Ошибка загрузки фото', prepareErrorMessage(e))
      throw e
    } finally {
      this.applyData({ [!isCamera ? 'isProcessPhoto' : 'isProcessSaveCamera']: false })
    }
  }

  next = async (params = {}) => {
    const { isForce = false } = params
    if (!isForce && this.ctx.CommonModel.isMetaStep()) return

    if (!this.isNext) {
      this.applyData({ isForceValidate: true })

      if (!this.isValidPhoto) scrollTo(this.PHOTO_BLOCK)
      else if (!this.isValidContacts) scrollTo(this.CONTACTS_BLOCK)
      else if (!this.isValidEducation) scrollTo(this.EDUCATION_BLOCK)
      else if (!this.isValidFamily) scrollTo(this.FAMILY_BLOCK)
      else if (!this.isValidEmployment) scrollTo(this.EMPLOYMENT_BLOCK)
      else if (!this.isValidContactPerson) scrollTo(this.CONTACT_PERSON_BLOCK)
      else if (!this.isValidAllIncoming) scrollTo(this.INCOMES_BLOCK)
      else if (!this.isValidAllRealty) scrollTo(this.REALTY_BLOCK)
      else if (!this.isValidAllAuto) scrollTo(this.AUTO_BLOCK)
      else if (!this.isValidAllDocuments) scrollTo(this.DOCUMENTS_BLOCK)
    } else {
      try {
        this.applyData({ isProcessSave: true })
        const data = await FactoryProvider.QuestionaryProvider.updateQuestionary(this.ctx.CommonModel.id, this.get(), CUSTOM_STEP)
        this.ctx.CommonModel.applyDataAfterUpdate(data)
      } catch (e) {
        notifyError('Ошибка обновления прочих сведений', prepareErrorMessage(e))
        throw e
      } finally {
        this.applyData({ isProcessSave: false })
      }
    }
  }

  back = () => {
    this.ctx.CommonModel.setStep(3)
  }
}

export { OtherModel }
